const wordEndingWithSymbolPattern = /[A-Za-z0-9-_]+([.,])$/;

const partitionText = (text, maxWords) => {
  if (!text) {
    return "";
  }

  text = text.replace(/\n/g, " ");
  const results = [];
  const words = text.split(" ");

  for (let i = 0; i < words.length; i = i + maxWords) {
    results.push(words.slice(i, i + maxWords));
  }

  return results;
};

export const processText = (config) => {
  const {
    text,
    alternator,
    maxWords = 10,
    normalTextPrefix,
    alternatedTextPrefix,
  } = config;
  const results = [];
  const partitionedText = partitionText(text, maxWords);

  for (let i = 0; i < partitionedText.length; i++) {
    results.push("\n");

    if (alternator(i, partitionedText)) {
      if (normalTextPrefix) {
        results.push(normalTextPrefix);
      }

      results.push(partitionedText[i]);
    } else {
      const reversedSentence = partitionedText[i].reverse();

      for (let i = 0; i < reversedSentence.length; i++) {
        const wordEndingWithSymbolIdx = reversedSentence.findIndex((v) =>
            wordEndingWithSymbolPattern.test(v)
        );
        if (wordEndingWithSymbolIdx !== -1) {
          const word = reversedSentence[wordEndingWithSymbolIdx];
          let symbol = word.match(wordEndingWithSymbolPattern)[1];
          reversedSentence[wordEndingWithSymbolIdx] = `${symbol}${word.slice(
              0,
              word.length - 1
          )}`;
        }
      }

      results.push(reversedSentence);

      if (alternatedTextPrefix) {
        results.push(alternatedTextPrefix);
      }
    }
  }

  return results.flat().join(" ");
};

export const processLtr = (text, direction = true) =>
    processText({
      text,
      alternator: (i) => !(i % 2),
      ...(direction
          ? {
            alternatedTextPrefix: "⏪",
            normalTextPrefix: "⏩",
          }
          : {}),
    });

export const processRtl = (text, direction = true) =>
    processText({
      text,
      alternator: (i) => i % 2,
      ...(direction
          ? {
            alternatedTextPrefix: "⏪",
            normalTextPrefix: "⏩",
          }
          : {}),
    });

export const reverseString = (str) => {
  // Step 1. Use the split() method to return a new array
  const splitString = str.split(" "); // var splitString = "hello".split("");
  // ["h", "e", "l", "l", "o"]

  // Step 2. Use the reverse() method to reverse the new created array
  const reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
  // ["o", "l", "l", "e", "h"]

  // Step 3. Use the join() method to join all elements of the array into a string
  // var joinArray = ["o", "l", "l", "e", "h"].join("");
  // "olleh"

  //Step 4. Return the reversed string
  return reverseArray.join(" "); // "olleh"
};

export const processTextForPrint = (config) => {
  const {
    text,
    alternator,
    maxWords = 9,
    normalTextPrefix,
    alternatedTextPrefix,
  } = config;
  const results = [];
  const partitionedText = partitionText(text, maxWords);

  for (let i = 0; i < partitionedText.length; i++) {
    results.push("\n");

    if (alternator(i, partitionedText)) {
      if (normalTextPrefix) {
        results.push(normalTextPrefix);
      }

      results.push(partitionedText[i]);
    } else {
      const reversedSentence = partitionedText[i].reverse();

      for (let i = 0; i < reversedSentence.length; i++) {
        const wordEndingWithSymbolIdx = reversedSentence.findIndex((v) =>
            wordEndingWithSymbolPattern.test(v)
        );
        if (wordEndingWithSymbolIdx !== -1) {
          const word = reversedSentence[wordEndingWithSymbolIdx];
          let symbol = word.match(wordEndingWithSymbolPattern)[1];
          reversedSentence[wordEndingWithSymbolIdx] = `${symbol}${word.slice(
              0,
              word.length - 1
          )}`;
        }
      }

      results.push(reversedSentence);

      if (alternatedTextPrefix) {
        results.push(alternatedTextPrefix);
      }
    }
  }

  console.log(results.flat(), "he there");

  return results.flat().join(" ");
};

export const processLtrInASingleDir = (text, direction = true) =>
    processTextForPrint({
      text,
      alternator: () => false,
      ...(direction
          ? {
            alternatedTextPrefix: "",
            normalTextPrefix: "",
          }
          : {}),
    });
