import { useCallback, useEffect, useState, Fragment } from "react";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  TextareaAutosize,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
// import MenuIcon from '@material-ui/icons/Menu';
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import Logo from "./assets/newLogo.svg";
import {
  processLtr,
  processLtrInASingleDir,
  processRtl,
  processRtlHebrew,
  processTextHebrew,
  reverseString,
} from "./utils";
import SwipeableViews from "react-swipeable-views";
import "./App.css";
import { TabPanel } from "./components/TabPanel";
import { useLocalStorage } from "./hooks/useLocalStorage";
import AnimatedLogo from "./assets/logo/logo.png";
// import SplashVideo from './assets/splash_video.m4v';
import YouTubeIcon from "@material-ui/icons/YouTube";

// Docxtemplater and PizZip used for file upload .docx files
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  textArea: {
    padding: "5px",
    cols: "20",
    width: "100%",
    maxWidth: "100%",
    maxHeight: "250px",
    overflowY: "auto !important",
    resize: "none",
    whiteSpace: "pre-line",
  },
  separator: {
    padding: "20px",
  },
  navbar: {
    width: "100%",
    "& .MuiTabs-flexContainer": {
      display: "flex",
      // justifyContent: theme.breakpoints.down('sm') ? 'flex-start' : 'center',
    },
  },
  footer: {
    width: "100%",
    padding: "15px",
    marginTop: "auto",
  },
  logo: {
    maxWidth: "100px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "50px",
    },
  },
  copyRight: {
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "9px",
    },
  },
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function App() {
  const [text, setText] = useState({
    before: "",
    after: "",
  });
  const [logoStatus, setLogoStatus] = useState(true);

  // const [videoIsRunning, setVideoIsRunning] = useState(true);
  const { check, get, set } = useLocalStorage();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isWideLandscape = useMediaQuery(
      "screen and (orientation:landscape) and (min-width: 600px)"
  );
  const isMdOrLess = useMediaQuery("(max-width: 960px");
  const isPhone = isWideLandscape && "ontouchstart" in window;
  const classes = useStyles();
  const [textDirection, setTextDirection] = useState("ltr");
  const [showDirection, setShowDirection] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [colors, setColors] = useState({
    backgroundColor: "#9D00FF",
    navbarColor: "#C528FF",
    navText: "black",
  });
  const menuItemOnClick = (idx) => {
    setTabIndex(idx);
    // setDrawerOpen(false);
  };

  const WordCounter = (str) => {
    var words = str.split(" ").length;
    for (let i = 0; i < words; i++) {
      if (str.split(" ")[i] == "45") {
        // break word here
        str.split(" ")[i] += "\n";
      }
    }
    return words;
  };

  const handleDarkMode = () => {
    if (colors.backgroundColor === "#eeeeee") {
      setColors({
        backgroundColor: "#9D00FF",
        navbarColor: "#C528FF",
        navText: "black",
      });
    } else {
      setColors({
        backgroundColor: "#eeeeee",
        navbarColor: "#3a6ab4",
        navText: "white",
      });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLogoStatus(false);
    }, 6500);
  }, []);
  const textProcessor = textDirection === "ltr" ? processLtr : processRtl;

  const processText = useCallback(
      (text) => {
        let processedText = textProcessor(text, showDirection);
        // console.log(typeof processedText);
        // console.log(processedText);

        // break word after 45 letters
        let lines = text.split("\n");
        let last_line = lines[lines.length - 1].split(" ")[0];
        if (last_line.length >= 45) {
          text += "\n";
        }

        if (textDirection == "rtl") {
          text = formatText(text);
        }

        setText(() => ({
          before: text,
          after: processedText,
        }));
        set("text", text);
      },
      [showDirection, textProcessor, set]
  );

  // formated text
  const formatText = (uploadedText) => {
    if (textDirection === "rtl") {
      uploadedText = uploadedText.replace(/ /g, " \u200F");
      uploadedText = uploadedText.replace(/\n/g, "\n\u200F");
    } else {
      uploadedText = uploadedText.replace(/ /g, "\u200F ");
      uploadedText = uploadedText.replace(/\n/g, "\u200F\n");
    }
    return uploadedText;
  };
  const clearText = () => {
    processText("");
  };

  useEffect(() => {
    if (check("text")) {
      let text = get("text");
      text = formatText(text);
      processText(text);
    }
  }, [textDirection]);

  useEffect(() => {
    if (text.before) {
      const processedText = textProcessor(text.before, showDirection);
      setText((oldText) => ({
        ...oldText,
        after: processedText,
      }));
    }
  }, [showDirection, text.before, textProcessor]);

  const handleChange = (newValue) => {
    setTabIndex(newValue);
  };

  // handleKeyDown
  function lastWord(words) {
    let n = words.replace(/[\[\]?.,\/#!$%\^&\*;:{}=\\|_~()]/g, "").split(" ");
    return n[n.length - 1];
  }

  const popupClickEvent = (url) =>{
    window.open(url, 'sharer', 'toolbar=0,status=0,width=1200,height=800');
  }

  // handle File
  const handleFile = (e) => {
    e.preventDefault();
    const fileType = e.target.files[0].type;
    console.log(fileType);
    let uploadedText = "";

    // if the file type is .txt
    if (fileType === "text/plain") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const fileText = e.target.result;

        uploadedText = formatText(fileText.toString());
        let processedText = textProcessor(uploadedText, showDirection);
        setText(() => ({
          before: uploadedText,
          after: processedText,
        }));
        set("text", fileText);
      };
      reader.readAsText(e.target.files[0]);
    }
    // if the file type is .docx
    else if (
        fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        fileType === "application/msword"
    ) {
      e.preventDefault();
      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;
        const doc = new Docxtemplater(new PizZip(content), {
          delimiters: {
            start: "12op1j2po1j2poj1po",
            end: "op21j4po21jp4oj1op24j",
          },
        });
        const fullText = doc.getFullText();

        uploadedText = formatText(fullText.toString());
        let processedText = textProcessor(uploadedText, showDirection);

        setText(() => ({
          before: uploadedText,
          after: processedText,
        }));
        set("text", fullText);
        console.log(fullText);
      };
      reader.readAsBinaryString(e.target.files[0]);
    }
  };

  const navLinksText = [
    "Home",
    "How it all began",
    "How to use",
    "Rights to use",
    "About the inventor",
    "Donate",
    "Legal",
  ];
  const appBar = (
      <AppBar
          elevation={0}
          position="static"
          style={{
            backgroundColor: colors.navbarColor,
            color: colors.navText,
          }}
      >
        {/*{isMdOrLess &&*/}
        {/*<div style={{display: 'flex', justifyContent: 'space-around'}}>*/}

        {/*    <IconButton edge="start" color="inherit" aria-label="menu"*/}
        {/*                onClick={() => setDrawerOpen(!drawerOpen)}>*/}
        {/*        <MenuIcon/>*/}
        {/*    </IconButton>*/}
        {/*    <img src={Logo} className={classes.logo}/>*/}
        {/*</div>*/}
        {/*}*/}

        {/*{isMdOrLess &&*/}
        {/*<Drawer anchor='top' open={drawerOpen} onClose={() => setDrawerOpen(false)}>*/}
        {/*    <List>*/}
        {/*        {navLinksText.map((v, i) =>*/}
        {/*            <ListItem key={v} button onClick={() => menuItemOnClick(i)} {...a11yProps(i)}>*/}
        {/*                <ListItemText primary={v}/>*/}
        {/*            </ListItem>*/}
        {/*        )}*/}
        {/*    </List>*/}
        {/*</Drawer>*/}
        {/*}*/}

        {/*{!isMdOrLess &&*/}
        <Tabs
            value={tabIndex}
            onChange={handleChange}
            indicatorColor="primary"
            // scrollButtons="off"
            // textColor="primary"
            // centered={true}
            variant="scrollable"
            scrollButtons="on"
            aria-label="scrollable auto tabs example"
            style={{ justifyContent: "flex-start" }}
        >
          {navLinksText.map((v, i) => (
              <Tab
                  key={i}
                  wrapped
                  label={v}
                  {...a11yProps(i)}
                  onClick={() => menuItemOnClick(i)}
              />
          ))}
        </Tabs>
        {/*}*/}
      </AppBar>
  );
  if (logoStatus) {
    return (
        <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: colors.backgroundColor,
              // height: '100%',
              minHeight: "100vh",
            }}
        >
          <img
              src={AnimatedLogo}
              className="animatedLogo"
              style={{ maxWidth: "90%", margin: "auto" }}
          />
        </div>
    );
  }
  return (
      <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: colors.backgroundColor,
            // height: '100%',
            minHeight: "100vh",
          }}
      >
        <nav className={classes.navbar}>
          <div className={classes.root}>{appBar}</div>
        </nav>

        <Container
            maxWidth="sm"
            style={{
              height: "100% ",
              background: colors.backgroundColor,
            }}
        >
          <SwipeableViews
              axis={textDirection === "rtl" ? "x-reverse" : "x"}
              index={tabIndex}
              onChangeIndex={(idx) => setTabIndex(idx)}
              style={{ height: "100%" }}
          >
            <TabPanel value={tabIndex} index={0}>
              <Grid container justifyContent="space-evenly" spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                      control={
                        <Tooltip title="Right-to-left languages like Arabic, Persian, Urdu">
                          <Checkbox
                              checked={textDirection === "rtl"}
                              onChange={() =>
                                  setTextDirection(
                                      textDirection === "ltr" ? "rtl" : "ltr"
                                  )
                              }
                          />
                        </Tooltip>
                      }
                      label="Hebrew"
                  />

                  <FormControlLabel
                      control={
                        <Checkbox
                            checked={showDirection}
                            onChange={() => setShowDirection(!showDirection)}
                        />
                      }
                      label="Directions"
                  />

                  <Button onClick={handleDarkMode}>Color mode</Button>

                  <Button onClick={clearText}>Clear</Button>

                  <input
                      style={{ display: "none" }}
                      id="contained-button-file"
                      type="file"
                      accept=".txt, .doc, .docx"
                      onChange={(e) => handleFile(e)}
                  />
                  <label htmlFor="contained-button-file">
                    <Button component="span">Upload</Button>
                  </label>
                </Grid>

                <Grid item xs={isPhone ? 6 : 12}>
                  <TextareaAutosize
                      className={classes.textArea}
                      minRows={12}
                      maxRows={20}
                      placeholder={"Start typing.."}
                      // value={
                      //   textDirection == "rtl"
                      //     ? text.before.split(/\s/).reverse().join(" ")
                      //     : text.before
                      // }
                      value={text.before}
                      style={{
                        direction: textDirection,
                        textAlign: textDirection === "rtl" ? "right" : "left",
                      }}
                      dir={textDirection === "rtl" ? "ltr" : textDirection}
                      // lang={textDirection === "rtl" ? "he" : "en"}
                      // onKeyDown={(evt) => handleKeyDown(evt)}
                      onChange={(evt) => processText(evt.target.value)}
                  />
                </Grid>

                <Grid item xs={isPhone ? 6 : 12}>
                  <TextareaAutosize
                      className={classes.textArea}
                      minRows={12}
                      maxRows={20}
                      placeholder={"Start typing.."}
                      value={text.after}
                      dir={textDirection === "rtl" ? "ltr" : textDirection}
                      // lang={textDirection === "rtl" ? "he" : "en"}
                      style={{
                        whiteSpace: "pre",
                        textAlign: textDirection !== "ltr" ? "right" : "",
                        lineBreak:
                            text.before.split(" ")[0].length >= 25
                                ? "anywhere"
                                : "normal",
                      }}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={1} dir={textDirection}>
              <Typography style={{ fontSize: "18px", textAlign: "center" }}>
                Hi, my name is Omar Mashiach White, and in 2015 I invented a writing system that will change the way that we write, type and text.
                I believe this technological system has the ability to realign both hemispheres of the brain.
                Here’s what I mean :)<br></br>
                In the English writing system, there’s a starting point and then words travel in a right direction and In the Hebrew writing system,
                there’s a starting point and then words travel in a left direction.
                Now imagine what happens when words travel in both directions?
                I’m honored, to share with the world a system, that’s unique to our reality.
                I’m extremely humbled that you’ve considered using this divine system and I pray that you enjoy it
                ❤️ Much love and success on your journey…
              </Typography>
            </TabPanel>
            <TabPanel value={tabIndex} index={2} dir={textDirection}>
              <Typography style={{ fontSize: "18px", textAlign: "center" }}>
                <a href="#" onClick={() => popupClickEvent('https://youtube.com/channel/UCY819JFIHfogzg0BoNLKrtQ')}>
                  Click here to learn how to use this new technology
                </a>
              </Typography>
            </TabPanel>
            <TabPanel value={tabIndex} index={3} dir={textDirection}>
              <Typography style={{ fontSize: "18px", textAlign: "center" }}>
                Everyone and I mean everyone in the world have my blessings, to physically write in this new format.
                If you want to use this technology in your software or other tech-based projects
                then you must send all details, so that we can agree on the terms.
                If you simply want to use the technology on this website or even download it to your device
                then I humbly ask that you spread the word and leave feedback.
                If you want to integrate it on your own website, just make sure you give credit.
                All suggestions and feedback are welcomed to be sent to Omar@OmarSlimWhite.com
                <br />
                If you really like this system and would like to support more creative ideas then&nbsp;
                <a href="#" onClick={() => popupClickEvent("https://www.paypal.com/paypalme/OmarSlimWhite")}>
                  click here
                </a>
              </Typography>
            </TabPanel>
            <TabPanel value={tabIndex} index={4} dir={textDirection}>
              <Typography style={{ fontSize: "18px", textAlign: "center" }}>
                In 2015 I created the Devine Rewind writing system. It started with me trying to figure out,
                in what way can I elevate the way people operate. Make sure outside of this technology that
                you incorporate this practice in your daily life. If you have a habit of brushing your teeth with
                your right hand then switch it up and use your left hand.
                If you’re comfortable with driving while your left hand is on the steering wheel,
                then see how you feel when driving with your right hand.
                These are small examples of making sure you push your limits.
                Outside of being a teacher, I am also an&nbsp;
                <a href="#" onClick={() => popupClickEvent('https://www.domainmetaverse.io/')}>
                  author
                </a> &nbsp;a&nbsp;
                <a href="#" onClick={() => popupClickEvent('https://www.detoxemmunity.com/')}>
                  herbalist
                </a> &nbsp;and&nbsp;
                <a href="#" onClick={() => popupClickEvent('https://www.domainmetaverse.io/')}>
                  entrepreneur
                </a>
                <br />
                To learn more about me visit&nbsp;
                <a href="#" onClick={() => popupClickEvent('https://omarslimwhite.wixsite.com/omarslimwhite')}>
                  www.omarSlimWhite.com
                </a>
                <br />
                I love you with my highest love.
                ~Omar Mashiach White~

              </Typography>
            </TabPanel>

            <TabPanel value={tabIndex} index={5} dir={textDirection}>
              <Typography style={{ fontSize: "18px", textAlign: "center" }}>
                Every dollar will be used to change the world. Your contributions
                allow myself to focus on other technologies that will expand the
                consciousness of humanity. The next project that I’m working on is
                a very soohiscated bartering system. Economically many people have
                faced adversity in the world. Just because money may be limited to
                some, doesn’t mean people cant receive the things they desire
                through bartering, which is a very ancient practice. One BEING has
                something that the other BEING has and both beings have something
                of value to exchange. I’m really excited about this project so
                stays tuned for more projects centered around planetary shifting❤️
              </Typography>
              <div className={classes.separator} />
              <Button variant="contained">
                <a
                    style={{
                      textDecoration: "none",
                    }}
                    target={"_blank"}
                    href="https://www.paypal.com/paypalme/OmarSlimWhite"
                >
                  Donate
                </a>
              </Button>
            </TabPanel>
            <TabPanel value={tabIndex} index={6} dir={textDirection}>
              <Typography style={{ fontSize: "18px", textAlign: "center" }}>
                This technology isn’t meant to cure or treat any illness.
                Use this system at your own discretion.
                I assume no liability for anything that has been said or created.
                You have your own free will and discernment.
                Use what makes you feel wholesome. If you don’t like what’s being presented,
                you’re more than welcome to go back, to what makes you feel comfortable.
                I love you with my highest love
              </Typography>
            </TabPanel>
          </SwipeableViews>
        </Container>

        <div
            className={classes.footer}
            style={{ backgroundColor: colors.navbarColor }}
        >
          <Grid container alignItems={"center"} justifyContent="space-between">
            <Box>
              <img
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  className={classes.logo}
                  src={Logo}
                  alt="Logo"
              />
            </Box>

            <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
            >
              <Box>
                <a href="https://facebook.com/devinerewind" target="_blank">
                  <FacebookIcon style={{ color: "white" }} />
                </a>

                <a href="https://twitter.com/devinerewind" target="_blank">
                  <TwitterIcon style={{ color: "white" }} />
                </a>

                <a href="https://instagram.com/devinerewind" target="_blank">
                  <InstagramIcon style={{ color: "white" }} />
                </a>

                <a
                    href="https://youtube.com/channel/UCY819JFIHfogzg0BoNLKrtQ"
                    target="_blank"
                >
                  <YouTubeIcon style={{ color: "white" }} />
                </a>
              </Box>

              <Box>
                <Box
                    className={classes.copyRight}
                    style={{ textAlign: "center" }}
                >
                  © Copyright 2021 Design By DEVINE REWIND <br /> All rights
                  reserved.
                </Box>
              </Box>
            </Box>

            <Box>
              <img
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  className={classes.logo}
                  src={Logo}
                  alt="Logo"
              />
            </Box>
          </Grid>
        </div>
      </div>
  );
}

export default App;
